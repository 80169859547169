<template>
  <div class="member-detail-container type1" v-if="detail">
    <div @click="goBack" v-if="$bowser.mobile" class="mobile-back">
      <i></i>
      <p>back</p>
    </div>
    <div class="member__cover scrollObj">
      <img :src="detail.src" alt="" />
    </div>
    <div class="member__content">
      <div class="member__content__inner">
        <div class="member__base-info">
          <div class="member__name scrollObj">
            <div>{{ detail.name.cn }} {{ detail.name.en }}</div>
            <div class="close" @click="goBack"></div>
          </div>
          <div class="member__title scrollObj">
            <p>{{ detail.title.cn }} | {{ detail.title.en }}</p>
            <p>{{ detail.job.cn }} | {{ detail.job.en }}</p>
          </div>
        </div>
        <div class="animation__line scrollObj"></div>
        <div class="member__content-info scrollObj">
          <template v-if="$bowser.mobile">
            <div class="content__info" v-html="detail.summary"></div>
          </template>
          <el-scrollbar v-else>
            <div class="content__info" v-html="detail.summary"></div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapState } from 'vuex';
import events from '@/utils/eventBus';

export default {
  name: 'view-about-detail-1',
  computed: {
    ...mapState(['members']),
    detail() {
      const { id } = this.$route.params;
      this.pageInit();
      return this.members.length > 0
        ? this.members.find((item) => Number(item.id) === Number(id))
        : null;
    },
  },
  created() {
    if (this.members.length === 0) {
      this.getMembers();
    }
  },
  mounted() {
    this.$nextTick(() => {
      // setTimeout(() => {
      //   this.$refs.xxx.classList.add('animated');
      // }, 600);
      events.$emit('pageMounted');
    });
  },
  methods: {
    ...mapActions(['getMembers']),
    goBack() {
      this.$router.push({
        path: '/about#member',
      });
    },
  },
};
</script>

<style></style>
